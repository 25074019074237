<template>
  <div class="knives__youtube">
    <div class="container">
      <div class="knives__youtube-inner">
        <div class="knives__youtube-video">
          <iframe
            v-if="$i18n.locale === 'ru'"
            src="https://www.youtube.com/embed/w6EoPPrfqaQ"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <iframe
            v-else
            src="https://www.youtube.com/embed/Std2NAdG-eg"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
        <div class="knives__youtube-content">
          <div class="knives__youtube-logo">
            <img src="@/assets/img/icons/magnum-youtube.svg" alt="" />
          </div>
          <div class="knives__youtube-title">
            {{ $t("knives.youtubeTitle") }}
          </div>
          <p class="knives__youtube-text">Magnum Cash&Carry</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "KnivesYoutube",
};
</script>

<style lang="scss" scoped></style>
